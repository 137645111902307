import { Flex, useMultiStyleConfig } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { Breadcrumb as BreadcrumbItem } from '../../types/index';
import { withErrorBoundary } from '../ErrorBoundary';
import Module, { MarginType } from '../Module';
import BreadcrumbCollapsedMenu, { isCollapseItemOnMobile } from './BreadcrumbCollapsedMenu';
import { BreadcrumbDivider } from './BreadcrumbDivider';
import BreadcrumbLink from './BreadcrumbLink';

export interface BreadcrumbProps {
  breadcrumb: BreadcrumbItem[];
}

const Breadcrumb = ({ breadcrumb: breadcrumbItems = [] }: BreadcrumbProps) => {
  const breadcrumbTheme = useMultiStyleConfig('Breadcrumb', { baseStyles: true });

  const itemsToRender = useMemo(() => {
    const items: React.JSX.Element[] = [];
    breadcrumbItems.forEach((breadcrumbItem, index, breadcrumbItems) => {
      const collapseItemOnMobile = index !== 1 && isCollapseItemOnMobile(index, breadcrumbItems);
      if (index > 0) {
        items.push(
          <BreadcrumbDivider
            index={index}
            collapseItemOnMobile={collapseItemOnMobile}
            key={'divider-' + breadcrumbItem.url}
          />
        );
        if (index === 1) {
          items.push(<BreadcrumbCollapsedMenu breadcrumbItems={breadcrumbItems} key="menu" />);
        }
      }

      items.push(
        <BreadcrumbLink
          breadcrumbItem={breadcrumbItem}
          index={index}
          breadcrumbItems={breadcrumbItems}
          key={breadcrumbItem.url}
        />
      );
    });

    return items;
  }, [breadcrumbItems]);

  if (itemsToRender.length === 1) return null; // don't render at all on homepage

  return (
    <Module className="Breadcrumb" marginType={MarginType.SNUG} sx={breadcrumbTheme.module}>
      <Flex alignItems="center" gap={0} sx={breadcrumbTheme.stack}>
        {itemsToRender}
      </Flex>
    </Module>
  );
};

Breadcrumb.displayName = 'Breadcrumb';

export default withErrorBoundary(Breadcrumb);
