import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import Link from 'next/link';
import React from 'react';
import { Breadcrumb as BreadcrumbItem } from '../../types/index';
import ConditionalWrapper from '../ConditionalWrapper';
import { COLLAPSE_BREAKPOINT, NUMBER_OF_ITEMS_ON_MOBILE } from './constants';

interface BreadcrumbCollapsedMenuProps {
  breadcrumbItems: BreadcrumbItem[];
}

function isEnoughItemsToCollapse(breadcrumbItems: BreadcrumbItem[]) {
  return breadcrumbItems.length > NUMBER_OF_ITEMS_ON_MOBILE + 1; // + 1 to include home;
}

export function isCollapseItemOnMobile(index: number, breadcrumbItems: BreadcrumbItem[]) {
  const isHome = index === 0;
  const enoughItemsToCollapse = isEnoughItemsToCollapse(breadcrumbItems);

  return (
    !isHome && enoughItemsToCollapse && index < breadcrumbItems.length - NUMBER_OF_ITEMS_ON_MOBILE
  );
}

const BreadcrumbCollapsedMenu = ({ breadcrumbItems }: BreadcrumbCollapsedMenuProps) => {
  const breadcrumbTheme = useMultiStyleConfig('Breadcrumb', { baseStyles: true });
  const enoughItemsToCollapse = isEnoughItemsToCollapse(breadcrumbItems);

  const collapsedMenuItemsOnly: BreadcrumbItem[] = breadcrumbItems.reduce(
    (acc, item, index, array) => {
      if (isCollapseItemOnMobile(index, array)) {
        acc.push(item);
      }
      return acc;
    },
    [] as BreadcrumbItem[]
  );

  return (
    <Menu>
      <MenuButton
        display={{ base: enoughItemsToCollapse ? 'block' : 'none', [COLLAPSE_BREAKPOINT]: 'none' }}
        as={Button}
        variant="ghost"
        mx={-1}
        sx={breadcrumbTheme.menuButton}
      >
        <Box w={4} height={4} mx={-1} sx={breadcrumbTheme.menuIcon} />
      </MenuButton>
      <MenuList>
        {collapsedMenuItemsOnly.map((item) => (
          <ConditionalWrapper
            key={item.url}
            condition={!item.isNewWindow}
            wrapper={(children) => (
              <Link href={item.url} as={item.url} passHref legacyBehavior key={item.url}>
                {children}
              </Link>
            )}
          >
            <MenuItem
              {...(item.isNewWindow ? { rel: 'noopener noreferrer', target: '_blank' } : {})}
              as="a"
              href={item.url}
              sx={breadcrumbTheme.menuItem}
              key={item.url}
            >
              {item.title}
            </MenuItem>
          </ConditionalWrapper>
        ))}
      </MenuList>
    </Menu>
  );
};

export default BreadcrumbCollapsedMenu;
