import getConfig from 'next/config';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { isLoggedIn } from './helpers';

export default function withCheckLogin<T>(WrappedComponent: React.ComponentType<T>) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  // Creating the inner component. The calculated Props type here is the where the magic happens.
  const WithCheckLogin = (props: React.ComponentProps<typeof WrappedComponent>) => {
    const router = useRouter();
    useEffect(() => {
      function onWindowFocus() {
        if (!isLoggedIn()) {
          router.reload();
        }
      }

      if (getConfig().publicRuntimeConfig?.featureIsB2b) {
        window.addEventListener('focus', onWindowFocus);
      }

      return () => window.removeEventListener('focus', onWindowFocus);
    }, []);
    return <WrappedComponent {...(props as T)} />;
  };

  WithCheckLogin.displayName = `withCheckLogin(${displayName})`;

  return WithCheckLogin;
}
