import { Box, useMultiStyleConfig } from '@chakra-ui/react';
import React from 'react';
import { COLLAPSE_BREAKPOINT } from './constants';

interface BreadcrumbDividerProps {
  index: number;
  collapseItemOnMobile: boolean;
}

export const BreadcrumbDivider = ({ index, collapseItemOnMobile }: BreadcrumbDividerProps) => {
  const breadcrumbTheme = useMultiStyleConfig('Breadcrumb', { baseStyles: true });

  return (
    <Box
      alignSelf="stretch"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      flexShrink={0}
      key={index}
      w={5}
      display={collapseItemOnMobile ? { base: 'none', [COLLAPSE_BREAKPOINT]: 'block' } : 'block'}
      sx={breadcrumbTheme.dividerIcon}
    ></Box>
  );
};
