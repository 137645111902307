import {
  Box,
  Center,
  Link as Anchor,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  ResponsiveValue,
  SystemStyleObject,
  Text,
  useDisclosure,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import * as CSS from 'csstype';
import debounce from 'lodash.debounce';
import Link from 'next/link';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import useBrochurewareDictionary from '../../api/Dictionary/useBrochurewareDictionary';
import { Breadcrumb as BreadcrumbItem } from '../../types/index';
import ConditionalWrapper from '../ConditionalWrapper';
import { COLLAPSE_BREAKPOINT, NUMBER_OF_ITEMS_ON_MOBILE } from './constants';

interface BreadcrumbLinkProps {
  breadcrumbItem: BreadcrumbItem;
  index: number;
  breadcrumbItems: BreadcrumbItem[];
}

interface SharedAnchorProps {
  _focusVisible: SystemStyleObject;
  alignSelf: string;
  borderRadius: number;
  display: ResponsiveValue<CSS.Property.Display>;
  flexShrink?: number;
  overflow: string;
  rel?: string;
  sx?: SystemStyleObject;
  target?: string;
  title?: string;
}

const BreadcrumbLink = ({
  breadcrumbItem: { title, url, isNewWindow },
  breadcrumbItems,
  index,
}: BreadcrumbLinkProps) => {
  const minHeight = 12;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { labels } = useBrochurewareDictionary('Breadcrumb');
  const breadcrumbTheme = useMultiStyleConfig('Breadcrumb', { baseStyles: true });
  const textRef = useRef<HTMLParagraphElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  const checkIsTruncated = useCallback(() => {
    if (textRef.current) {
      const isTruncatedNow = textRef.current.offsetWidth < textRef.current.scrollWidth;
      if (isTruncatedNow !== isTruncated) {
        setIsTruncated(isTruncatedNow);
      }
    }
  }, [isTruncated]);

  /**
   * Our popover showing title will only show if text is actually truncated, we need to respond
   * to the user resizing the browser.
   */
  useEffect(() => {
    const handleResize = debounce(checkIsTruncated, 500);

    checkIsTruncated();
    window.addEventListener('resize', handleResize, { passive: true });

    return () => window.removeEventListener('resize', handleResize);
  }, [checkIsTruncated]);

  const createHomeIcon = () => (
    <Box
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      flexShrink={0}
      w={6}
      h="100%"
      minHeight={minHeight}
      sx={breadcrumbTheme.homeIcon}
    ></Box>
  );

  const createBreadcrumbText = (title: string, allowTruncation?: boolean) => {
    let props = {};
    if (allowTruncation) {
      props = {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      };
    }
    return (
      <Center minHeight={minHeight} h="100%" px={1} py={1} sx={breadcrumbTheme.textCenter}>
        <Text fontSize="sm" m={0} {...props} sx={breadcrumbTheme.text} ref={textRef}>
          {title}
        </Text>
      </Center>
    );
  };

  const isHome = index === 0;
  const enoughItemsToCollapse = breadcrumbItems.length > NUMBER_OF_ITEMS_ON_MOBILE + 1; // + 1 to include home
  const collapseOnMobile =
    !isHome && enoughItemsToCollapse && index < breadcrumbItems.length - NUMBER_OF_ITEMS_ON_MOBILE;
  const allowTruncateOnMobile = !collapseOnMobile && !isHome;

  const sharedLinkProps = {
    href: url,
    key: url,
  };

  const sharedAnchorProps: SharedAnchorProps = {
    _focusVisible: { boxShadow: 'var(--chakra-shadows-inner)' },
    alignSelf: 'stretch',
    borderRadius: 0,
    display: collapseOnMobile ? { base: 'none', [COLLAPSE_BREAKPOINT]: 'block' } : 'block',
    sx: breadcrumbTheme.anchor,
    overflow: 'hidden',
  };

  if (isNewWindow) {
    sharedAnchorProps.target = '_blank';
    sharedAnchorProps.rel = 'noopener noreferrer';
  }

  const homeLabel = labels('home');
  let breadcrumbContent;
  if (isHome) {
    if (homeLabel !== 'Breadcrumb.home') {
      breadcrumbContent = createBreadcrumbText(homeLabel, allowTruncateOnMobile);
    } else {
      sharedAnchorProps.title = title;
      breadcrumbContent = createHomeIcon();
    }
    sharedAnchorProps.flexShrink = 0; // we don't want the home item to shrink
  } else {
    breadcrumbContent = createBreadcrumbText(title, allowTruncateOnMobile);
  }

  return (
    <>
      {/* If this is an internal link wrap in next link*/}
      <ConditionalWrapper
        condition={!isNewWindow}
        wrapper={(children) => (
          <Link {...sharedLinkProps} as={url} passHref legacyBehavior>
            {children}
          </Link>
        )}
      >
        <Anchor
          {...(isNewWindow ? { ...sharedLinkProps } : {})}
          {...sharedAnchorProps}
          onMouseOver={onOpen}
          onMouseDown={onOpen}
          onTouchStartCapture={onOpen}
          onTouchEnd={onClose}
          onMouseUp={onClose}
          onMouseLeave={onClose}
          _focusVisible={{ boxShadow: 'var(--chakra-shadows-inner)' }} // utilises inner version of the default focus highlight
        >
          {/* If this can be truncated wrap in a popover*/}
          <ConditionalWrapper
            condition={isTruncated}
            wrapper={(children) => (
              <Popover
                isOpen={isOpen}
                onClose={onClose}
                closeOnBlur={true}
                placement="top"
                autoFocus={false}
                variant="dark"
                size="sm"
              >
                <PopoverTrigger>{children}</PopoverTrigger>
                <PopoverContent
                  width="auto"
                  maxWidth="calc(100vw - var(--chakra-space-4))"
                  mx={2}
                  sx={breadcrumbTheme.popoverContent}
                >
                  <PopoverArrow sx={breadcrumbTheme.popoverArrow} />
                  <PopoverBody>{title}</PopoverBody>
                </PopoverContent>
              </Popover>
            )}
          >
            {breadcrumbContent}
          </ConditionalWrapper>
        </Anchor>
      </ConditionalWrapper>
    </>
  );
};

export default BreadcrumbLink;
