import React from 'react';
import Breadcrumb from '../components/Breadcrumb';
import ContentPlaceholder from '../components/ContentPlaceholder';
import withCheckLogin from '../components/Login/withCheckLogin';
import { Page } from '../types';
export { getStaticProps } from '../utils/next';

const Index = ({ bannerItem, breadcrumb, contentPlaceholder, hasTransparentHeader }: Page) => {
  return (
    <>
      {bannerItem?.length && (
        <ContentPlaceholder components={bannerItem} hasTransparentHeader={hasTransparentHeader} />
      )}
      <Breadcrumb breadcrumb={breadcrumb} />
      {contentPlaceholder && <ContentPlaceholder components={contentPlaceholder} />}
    </>
  );
};

export const getStaticPaths = async () => {
  return {
    paths: [],
    fallback: 'blocking', // don't render a skeleton, make wait for server rendered content
  };
};

export default withCheckLogin(Index); // a simple check wether to reload if user has no access_token
